<template>
  <section class="hero">
    <iframe
      :style="{
        height: 'fit-content',
        maxHeight: '100vh',
        height: 'calc(100vh - 219px)',
      }"
      :src="`https://theoremreach.com/respondent_entry/direct?api_key=0ef84efa4096353e8d1bf69f9521&user_id=${userId}&transaction_id=${Date.now()}`"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Theoremreach",
  computed: {
    ...mapGetters({
      email: "getEmail",
    }),
  },
  data() {
    return {
      userId: btoa(this.$store.state.email).replace(/=/g, ""),
    };
  },
  watch: {
    email(newEmail) {
      this.userId = btoa(newEmail).replace(/=/g, "");
    },
  },
};
</script>

<style>
</style>
